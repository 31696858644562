import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
        <div className="content">
            <div className="inner">
                <h1>Kevin Hu</h1>
                <h5>U of T Computer Science Student</h5>
                <h5>Software Engineer</h5>
                <h5>Photographer</h5>
            </div>
            <nav>
                <ul>
                    <li><a href="https://resume.kevinhu.co">Resume</a></li>
                    <li><a href="https://blog.kevinhu.co">Blog</a></li>
                    <li><a href="javascript:;" onClick={() => { props.onOpenArticle('about') }}>About</a></li>
                </ul>
            </nav>
        </div>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}
export default Header
