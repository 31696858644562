import React from 'react'
import PropTypes from 'prop-types'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => { this.props.onCloseArticle() }}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}>
        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">About</h2>
          <p>Hey, I'm Kevin! <br></br>
            I study Computer Science at University of Toronto and I'm currently looking for full time employment starting after May 2020.
          <br></br><br></br>
            Beyond computer science, I’m also interested in economics, finance, and languages. I love to cook and bake, do photography, hike, read, and listen to podcasts in my free time.
          <br></br><br></br>
            I’m always learning about the next new thing and creating something new.
          <br></br><br></br>
            If you’d like get in touch, send me a message at <a href="mailto:hello@kevinhu.co">hello@kevinhu.co</a>, <a href="tel:+14152373390">+1 415 237 3390</a>, or using one of the links below. I’d love to talk to you! I’m always trying to better myself as well, so feel free to send any comments too.</p>
          <ul className="icons">
            <li><a href="https://www.instagram.com/awesomekevin/" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="https://www.linkedin.com/in/awesomekevin" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            <li><a href="https://wa.me/14152373390" className="icon fa-whatsapp"><span className="label">WhatsApp</span></a></li>
            <li><a href="mailto:hello@kevinhu.co" className="icon fa-envelope"><span className="label">Email</span></a></li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
